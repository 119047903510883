<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				<el-input v-model="searchContex" placeholder="请输入节目单搜索" clearable></el-input>
				<el-button @click="search" class="m_btn_search">搜索</el-button>
			</div>
		</div>
		<div class="m_common_middle">
			<div class="m_table_content">
				<el-table :data="tableData" stripe>
					<el-table-column label="序号" type="index" width="100" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="name" label="广告节目单名称" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="position" label="广告位置" show-overflow-tooltip>
						<template slot-scope="scope">
							<i v-if="scope.row.position === 'top'" >顶部</i>
              				<i v-else>底部</i>
						</template>
					</el-table-column>
					<el-table-column prop="expireDate" label="有效期" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="status" label="状态" show-overflow-tooltip>
						<template slot-scope="scope">
							<i v-if="scope.row.status === 'Valid'" style="color: #62c462">正常</i>
              				<i v-else style="color: #ff4343">禁用</i>
						</template>
					</el-table-column>
					<el-table-column label="操作" show-overflow-tooltip>
						<template slot-scope="scope">
							<el-button type="text" style="color: #2d8cf0;background-color: inherit !important;" @click="see(scope.row)">查看节目播放统计</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="m_common_bottom">
			<m-pagination @pageChange="pageChange" :total="total"></m-pagination>
		</div>
		
		<el-dialog :close-on-click-modal="false" title="节目单统计" :visible.sync="isShowlogs" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="dialogTopStyle">
				<div style="display: flex;align-items: center;margin-bottom: 15px;">
					<p class="h1s">2010/10  ~  2012/6</p> 
					<p class="h1s">节目单名称</p>
					<el-button @click="isShowlogs = false" size="small">关闭</el-button>
					<el-button type="primary" @click="exportLog" size="small">导出</el-button>
				</div>
				<el-table :data="logsList" stripe>
					<el-table-column label="序号" type="index" width="100" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="adName" label="广告名称" show-overflow-tooltip>
						<template slot-scope="scope">
							{{scope.row.ad.adName}}
						</template>
					</el-table-column>
					<el-table-column prop="adType" label="广告类型" show-overflow-tooltip>
						<template slot-scope="scope">
								{{scope.row.ad.adType}}
						</template>
					</el-table-column>
					<el-table-column prop="adClient" label="客户名称" show-overflow-tooltip>
						<template slot-scope="scope">
							{{scope.row.ad.adClient}}
						</template>
					</el-table-column>
					<el-table-column prop="playCount" label="播放次数" show-overflow-tooltip>
						<template slot-scope="scope">
							{{scope.row.ad.playCount}}
						</template>
					</el-table-column>
					<el-table-column label="预览" show-overflow-tooltip>
						<template slot-scope="scope">
							<a :href="fileUrl + scope.row.ad.adUrl" target="_blank" style="color: #2d8cf0">查看</a>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import elDragDialog from "@/directive/el-drag-dialog";
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE, XHR_URL } from "@/utils/config";
	import { adsplayprogs,machineplayadsReports, machineplayadsExport } from "@/api/reportForm";
	export default {
		directives: {
			elDragDialog
		},
		data() {
			return {
				currentPage: 1,
//				selectDate: null,
//				adpos: null,
				searchContex: null,
				total: 0,
				tableData: [],
				isShowlogs:false,
				logsList:[]
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
			fileUrl() {
				return XHR_URL;
			},
			dialogTopStyle() {
				return {
					maxHeight: document.body.offsetHeight * 0.6 + "px",
					height:'600px'
				};
			},
		},
		components: {
			mPagination,
		},
		async created() {
			this.select();
		},
		mounted() {},
		methods: {
			pageChange(page) {
				this.currentPage = page;
				this.search();
			},
			search() {
				this.select();
			},
			see(data){
				this.logsList = data.ads;
				this.isShowlogs=true;
			},
			exportLog(){
//				 this.exportFile({})
			},
			async select() {
				const res = await adsplayprogs({
					pageNo:this.currentPage,
					pageSize:this.pageSize,
					progName:this.searchContex
				});
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
			},
			async exportFile(queryData) {
				queryData = queryData ? queryData : {};
				const res = await machineplayadsExport(queryData);
				const {
					pageCount,
					results
				} = res;
				const a = document.createElement("a");
				const e = document.createEvent("MouseEvents");
				e.initEvent("click", false, false);
				a.href = this.fileUrl + results[0].url;
				a.download = results[0].fileName;
				a.dispatchEvent(e);
			}
		},
		beforeDestroy() {},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
	.h1s{
		font-size: 16px;
		font-weight: bold;
		margin: 0px 10px;
	}
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>